import * as Sentry from "@sentry/astro";

Sentry.init({
  environment: import.meta.env.STORYBLOK_ENV,


  dsn: "https://ffbfa41d436db26c6f4fea49d9f923dc@o4508382267047936.ingest.de.sentry.io/4508382276288592",

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Define how likely traces are sampled. Adjust this value in production,
  // or use tracesSampler for greater control.
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});
